import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import LogIn from "./Components/LogIn/LogIn.tsx";
import Articles from "./Components/Articles/Articles.tsx";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute.tsx";
import Error from "./Components/Error/Error.tsx";
import Article from "./Components/Article/Article.tsx";
import GuestLogIn from "./Components/GuestLogIn/GuestLogIn.tsx";
import Review from "./Components/Review/Review.tsx";

function App() {

  return (
    <div className="App">
        <header className="App-header">
            <h1>MedScience Publishing</h1>
        </header>

        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Navigate to="/logIn"/>} />
                <Route path={'/logIn'} element={<LogIn/>} />
                <Route path={'/guestLogIn/:id'} element={<GuestLogIn/>} />
                <Route path={'/articles'} element={<PrivateRoute><Articles/></PrivateRoute>}/>
                <Route path={'/articles/:id'} element={<PrivateRoute><Article/></PrivateRoute>}/>
                <Route path={'/review/:id'} element={<Review/>} />
                <Route path={'/*'} element={<Error/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
