export function createAPIRequest(url: string, method: string, data: any, headers: any) {
    url = process.env.REACT_APP_URL + url;
    const defaultHeaders = {
        'content-type': 'application/json',
    };
    const defaultData = {
        method: method,
        headers: headers ? headers : defaultHeaders,
        credentials: 'include'
    };
    let requestData;

    if (data) {
        const isFormData = data instanceof FormData;
        requestData = {
            ...defaultData,
            body: isFormData ? data : JSON.stringify(data),
        }
    }

    const request = new Request(url, data ? requestData : defaultData);

    return request;
}