import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

function Article() {
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        async function getArticle() {
            /*return doGetArticle(state.userId, state.submissionId)
                .then((data) => {
                })
                .catch((err) => {
                    console.log(err.message);
                });*/
        }

        getArticle().then(() => {});
    }, );

    return(
        <div>
            <div>
                Article information page
            </div>
            <br/>

            <div>
                User id:
                {state.userId}
            </div>
            <br/>
            <div>
                Submission id:
                {state.submissionId}
            </div>
            <br/>

            <button id={'goBack'} type="button" onClick={() => {navigate('/articles')}}>Go back</button>
        </div>
    )
}

export default Article;