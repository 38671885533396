import {useEffect, useState} from "react";
import {Article, ReviewSummary} from "../../api/types";
import './ReviewDialog.css';
import {doDownload, doGetReview, doUpload} from "../Review/useReview.ts";
import {doGetArticles} from "../Articles/useArticles.ts";

interface ReviewDialogProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    article: Article;
    setArticles: (val: Article[]) => void;
}

function ReviewDialog({ isOpen, setIsOpen, article, setArticles }: ReviewDialogProps) {
    const defaultReviewSummary: ReviewSummary = {
        reviewInformation: [],
        hasAccessToPost: false
    };
    const [reviewSummary, setReviewSummary] = useState<ReviewSummary>(defaultReviewSummary);
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        async function getReview(isAuthor: boolean) {
            return doGetReview(article.submissionId, isAuthor)
                .then((data) => {
                    console.log("ReviewDialog > GET > doGetReview: ", data);

                    setReviewSummary(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }

        if (isOpen) {
            const isAuthor = true;

            getReview(isAuthor).then(() => {});
        }
    }, [isOpen, article]);

    const handleFileUpload = async () => {
        return doUpload(article, file)
            .then((response) => {
                console.log('ReviewDialog > POST > doUpload: ', response);
            })
            .catch((error) => {
                console.error('Update failed with error: ', error.response ? error.response.data : error.message);
                return;
            })
            .then(() => {
                const isAuthor = true

                return doGetReview(article.submissionId, isAuthor)
                    .then((data) => {
                        console.log("ReviewDialog > GET > doGetReview: ", data);

                        setReviewSummary(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            })
            .then(() => {
                doGetArticles()
                    .then((data) => {
                        setArticles(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            })
    }

    function formatDate(date: string) {
        if (date !== '') {
            const newDate = new Date(date);

            return newDate.toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })
        }

        return date;
    }

    function getRole(versionNumber: number) {
        if (versionNumber === 0) {
            return ' (reviewer)';
        }

        return ' (author)';
    }

    const handleDownload = (submissionId: number, versionId: string) => {
        if (versionId) {
            doDownload(submissionId, versionId)
                .then((response) => {
                    return response.blob();
                }).then((blob) => {
                    const url = window.URL.createObjectURL(blob);

                    window.open(url, '_blank')!.focus();
                });
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleCloseButton = () => {
        setIsOpen(false);
        setReviewSummary(defaultReviewSummary);
    }

    const getButtonClassName = (versionNumber: number) => {
        return versionNumber !== 0 ? 'review-dialog-button' : '';
    }

    return(
        <dialog id={'reviewDialog'} className={'review-dialog-container'} open={isOpen}>
            <div className={'review-dialog-header'}>
                <button id={'closeReviewDialog'} onClick={handleCloseButton}>Close</button>
                <br/>
            </div>
            <br/>

            {reviewSummary.reviewInformation.length === 0 ? <div className={'review-dialog-content'}/> : <div className={'review-dialog-content'}>
                {reviewSummary.reviewInformation.map(reviewInformationElement =>
                    <div key={reviewSummary.reviewInformation.indexOf(reviewInformationElement)}>
                        {reviewInformationElement.fullName + getRole(reviewInformationElement.versionNumber) + ' on ' + formatDate(reviewInformationElement.date)}
                        <div className={'review-dialog-line'}>
                            <div className={getButtonClassName(reviewInformationElement.versionNumber)} onClick={() => {handleDownload(reviewInformationElement.submissionId, reviewInformationElement.versionId)}}>
                                {reviewInformationElement.versionNumber !== 0 ? <div>
                                    {'version_' + reviewInformationElement.versionNumber + '.docx'}
                                </div> : <div>
                                    {reviewInformationElement.comment}
                                </div>}
                            </div>
                        </div>
                        <br/>
                    </div>
                )}
            </div>}
            <br/>

            <input id='file' name={'file'} type='file' disabled={!reviewSummary.hasAccessToPost} onChange={(event) => {handleFileChange(event)}} />
            <br/>
            <br/>

            <button onClick={handleFileUpload} disabled={!file || !reviewSummary.hasAccessToPost}>Upload</button>
        </dialog>
    )
}

export default ReviewDialog;