import {useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {User} from "../../api/types";
import {doLogIn} from "./useLogIn.ts";
import './LogIn.css';

function LogIn() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const effectRan = useRef(false);

    useEffect(() => {
        console.log("LogIn > useEffect");

        async function orcidLogIn() {
            return doLogIn(`api/orcidLogIn/${code}`, null)
                .then((response) => {
                    if (response.error) {
                        setError('Invalid userName or password.');

                        return;
                    }

                    console.log('LogIn > POST > doLogIn (ORCID): ', response);

                    localStorage.setItem('isLoggedIn', 'true');
                    navigate('/articles');

                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('LogIn failed with error: ', error.response ? error.response.data : error.message);
                    setError('Invalid userName or password.');

                    return;
                });
        }

        if (code) {
            setIsLoading(true);

            if (!effectRan.current) {
                orcidLogIn().then(() => {});
            }

            effectRan.current = true;
        } else {
            const isLoggedIn: boolean = localStorage.getItem('isLoggedIn') === 'true';
            console.log('LogIn > isLoggedIn: ', isLoggedIn);

            if (isLoggedIn === true) {
                navigate('/articles')
            }
        }
    }, [code, navigate]);

    const handleORCIDLogIn = () => {
        const clientId = process.env.REACT_APP_ORCID_CLIENT_ID;
        const redirectURL = process.env.REACT_APP_ORCID_REDIRECT_URL;
        const authenticationURL = process.env.REACT_APP_ORCID_AUTHENTICATION_URL;
        // might need to replace with scope=/read-limited
        const authorisationURL = authenticationURL + `authorize?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${redirectURL}`;

        window.location.replace(authorisationURL);
    }

    const handleLogIn = async () => {
        if (!userName || !password) {
            setError('Please enter both userName and password.');

            return;
        }

        const user: User = {
            userName: userName,
            password: password,
            role: 'ROLE_ADMIN',
            submissionId: 0
        }

        return doLogIn('api/logIn', user)
            .then((response) => {
                if (response.error) {
                    setError('Invalid userName or password.');

                    return;
                }

                console.log('LogIn > POST > doLogIn: ', response);

                localStorage.setItem('isLoggedIn', 'true');

                navigate('/articles');
            })
            .catch((error) => {
                console.error('LogIn failed with error: ', error.response ? error.response.data : error.message);
                setError('Invalid userName or password.');

                return;
            })
    }

    return (
        <div>
            {isLoading ? <div/> : <div className={'mainContainer'}>
                <div className={'titleContainer'}>
                    <div>LogIn</div>
                </div>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={userName}
                        placeholder="Enter your user name"
                        onChange={(e) => setUserName(e.target.value)}
                        className={'inputBox'}
                    />
                </div>

                <br />

                <div className={'inputContainer'}>
                    <input
                        type="password"
                        value={password}
                        placeholder="Enter your password here"
                        onChange={(e) => setPassword(e.target.value)}
                        className={'inputBox'}
                    />
                </div>

                {error && <p className="text-danger">{error}</p>} {/* Render error message if exists */}

                <br />
                <div className={'inputContainer'}>
                    <input className={'inputButton'} type="button" onClick={handleLogIn} value={'Log in'} />
                </div>
                <br/>
                <div className={'inputContainer'}>
                    <input className={'inputButton'} type="button" onClick={handleORCIDLogIn} value={'Login with ORCID'} />
                </div>
            </div>}
        </div>
    )
}

export default LogIn;