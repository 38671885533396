import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {User} from "../../api/types";
import {doLogIn} from "./../LogIn/useLogIn.ts";

function GuestLogIn() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log('GuestLogIn > state', state);

    const handleLogIn = async () => {
        if (!userName || !password) {
            setError('Please enter both userName and password.');

            return;
        }

        const user: User = {
            userName: userName,
            password: password,
            role: 'ROLE_GUEST',
            submissionId: parseInt(params.id)
        }

        return doLogIn('api/logIn', user)
            .then((response) => {
                if (response.error) {
                    setError('Invalid userName or password.');

                    return;
                }

                console.log('GuestLogIn > POST > doLogIn: ', response);

                sessionStorage.setItem('isGuestLoggedIn', 'true');

                navigate(`/review/${params.id}`, {state: state});
            })
            .catch((error) => {
                console.error('LogIn failed with error: ', error.response ? error.response.data : error.message);
                setError('Invalid userName or password.');

                return;
            })
    }

    return (
        <div>
            <div>
                <div>Guest LogIn</div>
            </div>
            <br />
            <div>
                <input
                    value={userName}
                    placeholder="Enter your user name"
                    onChange={(e) => setUserName(e.target.value)}
                    className={'inputBox'}
                />
            </div>

            <br />

            <div>
                <input
                    value={password}
                    placeholder="Enter your code here"
                    onChange={(e) => setPassword(e.target.value)}
                    className={'inputBox'}
                />
            </div>

            {error && <p className="text-danger">{error}</p>} {/* Render error message if exists */}

            <br />
            <div>
                <input className={'inputButton'} type="button" onClick={handleLogIn} value={'Log in'} />
            </div>
        </div>
    )
}

export default GuestLogIn;