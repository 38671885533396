import {User} from "../../api/types";
import {createAPIRequest} from '../../api/useAPI.ts';

export async function doLogIn(url: string, user: User) {
    const request = createAPIRequest(url, 'POST', user, null);
    const response = await fetch(request);

    return await response.json();
}

export async function doLogOut() {
    const url = 'api/logOut';
    const request = createAPIRequest(url, 'POST', null, null);
    const response = await fetch(request);

    return await response.text();
}
