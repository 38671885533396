import {createAPIRequest} from "../../api/useAPI.ts";

export async function doGetArticles() {
    const url = 'api/articles';
    // TODO: try to let the headers as default one
    const headers = {};
    const request = createAPIRequest(url, 'GET', null, headers);
    const response = await fetch(request);

    return await response.json();
}

export function doDownload(id: number) {
    const url = `api/articles/${id}`;
    const headers = {
        accept: '*/*',
        'content-disposition': '*',
        filename: '*',
    };
    const request = createAPIRequest(url, 'GET', null, headers);

    return window.fetch(request);
}

export function doDelete(id: number) {
    const url = `api/articles/${id}`;
    // TODO: try to let the headers as the default one
    const headers = {};
    const request = createAPIRequest(url, 'DELETE', null, headers);

    return window.fetch(request);
}