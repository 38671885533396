import {SubmissionData} from "../../api/types";
import {createAPIRequest} from "../../api/useAPI.ts";

export async function doSubmit(submission: SubmissionData, file: File) {
    const url = 'api/articles/submit';
    const submissionBlob = new Blob(
        [JSON.stringify(submission)],
        {
            type: 'application/json'
        });

    const formData = new FormData();
    formData.append("submission", submissionBlob);
    formData.append("file", file);

    const request = createAPIRequest(url, 'POST', formData, {});
    const response = await fetch(request);

    return await response.text();
}