import {Navigate, useLocation} from "react-router-dom";

function PrivateRoute(props: {children: React.ReactNode}) {
    const { children } = props;
    // TODO: handle logOut in case if the token is expired
    const isLoggedIn: boolean = localStorage.getItem('isLoggedIn') === 'true';
    const location = useLocation();

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate
            replace={true}
            to="/logIn"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    )
}

export default PrivateRoute;