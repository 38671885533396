import {Navigate, useNavigate, useParams} from "react-router-dom";
import './Review.css';
import {useEffect, useState} from "react";
import {ReviewInformation, ReviewSummary} from "../../api/types";
import {doGetReview, doSubmitReview, doDownload} from "./useReview.ts";
import {doLogOut} from "../LogIn/useLogIn.ts";

function Review() {
    const navigate = useNavigate();
    const params = useParams();
    const isGuestLoggedIn: boolean = sessionStorage.getItem('isGuestLoggedIn') === 'true';
    const [comment, setComment] = useState<string | null>(null);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [isFeedbackBoxOpen, setIsFeedbackBoxOpen] = useState(false);
    const reviewContentClassName = isFeedbackBoxOpen ? 'review-content' : 'review-content-max';
    const [reviewSummary, setReviewSummary] = useState<ReviewSummary>(
        {
            reviewInformation: [
                {
                    fullName: "",
                    comment: "",
                    date: "",
                    submissionId: 0,
                    versionId: "",
                    versionNumber: 0,
                    requestChangesId: 1
                }
            ],
            hasAccessToPost: false
        }
    );

    useEffect(() => {
        const isAuthor = false;

        async function getReview() {
            return doGetReview(params.id, isAuthor)
                .then((data) => {
                    console.log("Review > GET > doGetReview: ", data);

                    setReviewSummary(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }

        getReview().then(() => {});
    }, []);

    setTimeout(() => {
        handleLogOut()
            .then(() => {});
    }, 3600000);

    const handleLogOut = async () => {
        return doLogOut()
            .then((response) => {
                console.log('Review > POST > doLogOut: ', response);

                sessionStorage.setItem('isGuestLoggedIn', 'false');

                navigate(`/guestLogIn/${params.id}`);
            })
    }

    const handleSubmit = async (requestChangesId: number) => {
        const reviewInformation: ReviewInformation = {
            fullName: "",
            comment: comment,
            date: "",
            submissionId: parseInt(params.id),
            versionId: "",
            versionNumber: 0,
            requestChangesId: requestChangesId
        }

        return doSubmitReview(reviewInformation)
            .then((response) => {
                if (response.error) {
                    return;
                }

                console.log('Review > POST > doSubmitReview: ', response);
            })
            .catch((error) => {
                console.error('LogIn failed with error: ', error.response ? error.response.data : error.message);

                return;
            })
            .then(() => {
                const isAuthor = false;

                return doGetReview(params.id, isAuthor)
                    .then((data) => {
                        console.log("Review > GET > doGetReview: ", data);

                        setReviewSummary(data);
                        setIsFeedbackBoxOpen(false);
                        setIsConfirmationDialogOpen(false);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            })
    }

    const handleDownload = (submissionId: number, versionId: string) => {
        if (versionId) {
            doDownload(submissionId, versionId)
                .then((response) => {
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);

                    window.open(url, '_blank')!.focus();
                });
        }
    };

    function formatDate(date: string) {
        if (date !== '') {
            const newDate = new Date(date);

            return newDate.toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })
        }

        return date;
    }

    function getRole(versionNumber: number) {
        if (versionNumber === 0) {
            return ' (reviewer)';
        }

        return ' (author)';
    }

    const getButtonClassName = (versionNumber: number) => {
        return versionNumber !== 0 ? 'review-button' : '';
    }

    return(
        <div>
            <dialog id={'confirmationDialog'} open={isConfirmationDialogOpen}>
                {'Are you sure you want to approve this version?'}
                <br/>
                {'You cannot made any changes after this action is completed.'}
                <br/>
                <br/>
                <button className={'review-yes-button'} onClick={() => handleSubmit(2)}>Yes</button>
                <button className={'review-no-button'} onClick={() => setIsConfirmationDialogOpen(false)}>No</button>
            </dialog>

            {isGuestLoggedIn ? <div className={'review-container'}>
                <div className={reviewContentClassName}>
                    {reviewSummary.reviewInformation.length > 0 && reviewSummary.reviewInformation.map(reviewInformationElement =>
                        <div key={reviewSummary.reviewInformation.indexOf(reviewInformationElement)}>
                            {reviewInformationElement.fullName + getRole(reviewInformationElement.versionNumber) + ' on ' + formatDate(reviewInformationElement.date)}
                            <div className={'review-line'}>
                                <div className={getButtonClassName(reviewInformationElement.versionNumber)} onClick={() => {handleDownload(reviewInformationElement.submissionId, reviewInformationElement.versionId)}}>
                                    {reviewInformationElement.versionNumber !== 0 ? <div>
                                        {'version_' + reviewInformationElement.versionNumber + '.docx'}
                                    </div> : <div>
                                        {reviewInformationElement.comment}
                                    </div>}
                                </div>
                            </div>

                            {(reviewSummary.hasAccessToPost && reviewInformationElement.versionNumber !== 0 && reviewSummary.reviewInformation.indexOf(reviewInformationElement) === reviewSummary.reviewInformation.length - 1) && <div>
                                <button className={'review-reject-button'} disabled={isFeedbackBoxOpen} onClick={() => setIsFeedbackBoxOpen(true)}>Reject</button>
                                <button className={'review-approve-button'} onClick={() => setIsConfirmationDialogOpen(true)}>Approve</button>
                            </div>}
                            <br/>
                        </div>
                    )}
                </div>
                <br/>

                <div hidden={!isFeedbackBoxOpen}>
                    <label>
                        {'Enter feedback:'}
                        <br/>
                        <textarea name="reviewMessage" value={comment} onChange={e => setComment(e.target.value)} rows={10} cols={70} />
                    </label>
                    <br/>
                    <br/>
                    <button onClick={() => handleSubmit(1)}>Submit review</button>
                </div>
            </div> : <Navigate
                replace={true}
                to={`/guestLogIn/${params.id}`}
            />}
        </div>
    )
}

export default Review;