function Error() {
    return(
        <div>
            <h2>
                {'This page does not exist'}
            </h2>
        </div>
    )
}

export default Error;