import {createAPIRequest} from "../../api/useAPI.ts";
import {Article, ReviewInformation} from "../../api/types";

export async function doGetReview(id: string | number, isAuthor: boolean) {
    const url = `api/review/${id}/${isAuthor}`;
    const headers = {};
    const request = createAPIRequest(url, 'GET', null, headers);
    const response = await fetch(request);

    return await response.json();
}

export async function doSubmitReview(reviewInformation: ReviewInformation) {
    const url = `api/review/submit`;
    const request = createAPIRequest(url, 'POST', reviewInformation, null);
    const response = await fetch(request);

    return await response.json();
}

export function doDownload(submissionId: number, versionId: string) {
    const url = `api/articles/${submissionId}/${versionId}`;
    const headers = {
        accept: '*/*',
        'content-disposition': '*',
        filename: '*',
    };
    const request = createAPIRequest(url, 'GET', null, headers);

    return window.fetch(request);
}

export async function doUpload(article: Article, file: File) {
    const url = 'api/articles';
    const articleBlob = new Blob(
        [JSON.stringify(article)],
        {
            type: 'application/json'
        });

    const formData = new FormData();
    formData.append("article", articleBlob);
    formData.append("file", file);

    const request = createAPIRequest(url, 'PUT', formData, {});
    const response = await fetch(request);

    return await response.text();
}