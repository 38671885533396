import {useNavigate} from "react-router-dom";
import './Articles.css';
import {useEffect, useState} from "react";
import {Article} from "../../api/types";
import Submission from "../Submission/Submission.tsx";
import ReviewDialog from "../ReviewDialog/ReviewDialog.tsx";
import {doLogOut} from "../LogIn/useLogIn.ts";
import {doDelete, doDownload, doGetArticles} from "./useArticles.ts";

function Articles() {
    const navigate = useNavigate();
    const [articles, setArticles] = useState<Article[]>([]);
    const [article, setArticle] = useState<Article | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const emptyStateInformation = 'You do not have any submission yet. Press the Submit article button to start.';

    useEffect(() => {
        async function getArticles() {
            setIsLoading(true);

            return doGetArticles()
                .then((data) => {
                    console.log("Articles > GET > doGetArticles: ", data);

                    setArticles(data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }

        getArticles().then(() => {});
    }, []);

    const handleDownload = (id: number) => {
        const data = doDownload(id);

        data.then((response) => {
            return response.blob();
        }).then((blob) => {
            const url = window.URL.createObjectURL(blob);

            window.open(url, '_blank')!.focus();
        });
    };

    const handleDelete = (id: number) => {
        return doDelete(id)
            .then(() => {
                doGetArticles()
                    .then((data) => {
                        console.log("Articles > DELETE > doDelete: ", data);

                        setArticles(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            });
    }

    const handleReviewDialogOpen = (article: Article) => {
        setArticle(article);
        setIsReviewDialogOpen(true);
    }

    const handleLogOut = async () => {
        return doLogOut()
            .then((response) => {
                console.log('Articles > POST > doLogOut: ', response);

                localStorage.setItem('isLoggedIn', 'false');

                navigate('/logIn');
            })
    }

    const handleNavigate = (userId: string, submissionId: number) => {
        const state = {
            userId: userId,
            submissionId: submissionId
        }

        navigate(`/articles/${submissionId}`, {state: state});
    }

    return (
        <div className={'articles-container'}>
            <br/>
            <button id={'openSubmissionModal'} onClick={() => {setIsModalOpen(true)}}>Submit article</button>
            <dialog id={'submissionModal'} className={'articles-modal-container'} open={isModalOpen}>
                <Submission setData={setArticles} setModal={setIsModalOpen}/>
            </dialog>

            <ReviewDialog isOpen={isReviewDialogOpen} setIsOpen={setIsReviewDialogOpen} article={article} setArticles={setArticles}/>

            {/*<button onClick={() => {history('/submission')}}>Submit article</button>*/}

            {articles.length === 0 && !isLoading ? <div><br/>{emptyStateInformation}<br/><br/></div> : <div className={'articles-files-container'}>
                {articles.map(article =>
                    <div className={'articles-line'} key={articles.indexOf(article)}>
                        <div className={'articles-relative-name'}>
                            {article.title}
                        </div>
                        <div className={'articles-element articles-version-number'}>
                            {article.versionsIds.length}
                        </div>
                        <div className={'articles-element articles-status'}>
                            {'PENDING REVIEW'}
                        </div>
                        <div className={'articles-button articles-element'} onClick={() => {handleDownload(article.submissionId)}}>
                            {'Download'}
                        </div>
                        <div className={'articles-button articles-element'} onClick={() => {handleReviewDialogOpen(article)}}>
                            {'Review'}
                        </div>
                        <div className={'articles-button articles-element'} onClick={() => {handleNavigate(article.userId, article.submissionId)}}>
                            {'Details'}
                        </div>
                        <div className={'articles-button articles-element'} onClick={() => {handleDelete(article.submissionId).then()}} hidden={article.userId !== 'test'}>
                            {'Delete'}
                        </div>
                    </div>
                )}
            </div>}

            <div>
                <button type="button" onClick={handleLogOut}>Log out</button>
            </div>
        </div>
    );
}

export default Articles;