import {useEffect, useState} from "react";
import {Article, Country, SubmissionData} from "../../api/types";
import {Reviewer} from "../../api/types";
import {Author} from "../../api/types";
import {doSubmit} from "./useSubmission.ts";
import './Submission.css';
import {doGetArticles} from "../Articles/useArticles.ts";

interface SubmissionProps {
    setData: (val: Article[]) => void;
    setModal: (val: boolean) => void;
}

function Submission({ setData, setModal }: SubmissionProps) {
    const [file, setFile] = useState<File | null>(null);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    let [submission, setSubmission] = useState<SubmissionData>({
        abstractText: '',
        articleTypeId: 1,
        coverLetter: '',
        hasConflictOfInterests: false,
        hasOpenPeerReview: false,
        hasPublishedMaterials: false,
        hasReceivedFunding: false,
        hasUsedAI: false,
        keywords: [''],
        numberOfAuthors: 0,
        numberOfPages: 0,
        title: '',
        reviewers: [
            {
                email: '',
                firstName: '',
                lastName: '',
                affiliation: ''
            }
        ],
        author: {
            affiliation: '',
            countryId: 1,
            email: '',
            firstName: '',
            isCorrespondingAuthor: false,
            lastName: '',
            middleName: '',
            showEmail: false,
            titleId: 1
        }
    });
    const [keywords, setKeywords] = useState<string[]>(['']);
    const [reviewers, setReviewers] = useState<Reviewer[]>([
        {
            email: '',
            firstName: '',
            lastName: '',
            affiliation: ''
        }
    ]);
    const [author, setAuthor] = useState<Author>({
        affiliation: '',
        countryId: 1,
        email: '',
        firstName: '',
        isCorrespondingAuthor: false,
        lastName: '',
        middleName: '',
        showEmail: false,
        titleId: 1

    });
    const countryOptions: Country[] = [
        {
            id: 1,
            name: 'Romania'
        },
        {
            id: 2,
            name: 'United States'
        }
    ];

    useEffect(() => {
        setSubmission(
            {
                ...submission,
                keywords: keywords,
                reviewers: reviewers,
                author: author
            }
        )

        let completedKeywordsNumber = 0;
        keywords.forEach(keyword => keyword ? completedKeywordsNumber++ : null);

        let completedReviewersNumber = 0;
        reviewers.forEach(reviewer => reviewer.firstName && reviewer.lastName && reviewer.email && reviewer.affiliation ? completedReviewersNumber++ : null);

        const hasCompletedKeywords = completedKeywordsNumber === keywords.length;
        const hasCompletedReviewersInformation = completedReviewersNumber === reviewers.length;
        const hasCompletedAuthorInformation = author.firstName && author.lastName && author.email && author.affiliation;

        if (hasCompletedKeywords && hasCompletedReviewersInformation && file) {
            setIsSubmitButtonDisabled(false);
        } else {
            setIsSubmitButtonDisabled(true);
        }
    }, [keywords, reviewers, author, file]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleModalClose = () => {
        setKeywords(['']);
        setReviewers([
            {
                email: '',
                firstName: '',
                lastName: '',
                affiliation: ''
            }
        ]);
        setAuthor(
            {
                affiliation: '',
                countryId: 1,
                email: '',
                firstName: '',
                isCorrespondingAuthor: false,
                lastName: '',
                middleName: '',
                showEmail: false,
                titleId: 1
            }
        );
        setSubmission(
            {
                abstractText: '',
                articleTypeId: 1,
                coverLetter: '',
                hasConflictOfInterests: false,
                hasOpenPeerReview: false,
                hasPublishedMaterials: false,
                hasReceivedFunding: false,
                hasUsedAI: false,
                keywords: [''],
                numberOfAuthors: 0,
                numberOfPages: 0,
                title: '',
                reviewers: [
                    {
                        email: '',
                        firstName: '',
                        lastName: '',
                        affiliation: ''
                    }
                ],
                author: {
                    affiliation: '',
                    countryId: 1,
                    email: '',
                    firstName: '',
                    isCorrespondingAuthor: false,
                    lastName: '',
                    middleName: '',
                    showEmail: false,
                    titleId: 1
                }
            }
        );
        /*setFile(null);*/
        setModal(false);
    }

    const handleAddInput = (isReviewer: boolean) => {
        if (isReviewer) {
            setReviewers([...reviewers, {
                email: '',
                firstName: '',
                lastName: '',
                affiliation: ''
            }]);
        } else {
            setKeywords([...keywords, '']);
        }
    };

    const handleChangeArrayInput = (event, index, isReviewer: boolean) => {
        if (isReviewer) {
            let { name, value } = event.target;
            let onChangeValue = [...reviewers];
            onChangeValue[index][name] = value;
            setReviewers(onChangeValue);
        } else {
            let onChangeValue = [...keywords];
            onChangeValue[index] = event.target.value;
            setKeywords(onChangeValue);
        }
    };

    const handleChangeInput = (event, isSubmission: boolean) => {
        let { name, value } = event.target;

        if (isSubmission) {
            let onChangeValue = {...submission};
            onChangeValue[name] = value;
            setSubmission(onChangeValue);
        } else {
            let onChangeValue = {...author};
            onChangeValue[name] = value;
            setAuthor(onChangeValue);
        }
    };

    const handleChangeCheckboxInput = (event) => {
        let name = event.target.name;
        let value = !submission.hasReceivedFunding;
        let onChangeValue = {...submission};
        onChangeValue[name] = value;
        setSubmission(onChangeValue);
    }

    const handleDeleteInput = (index, isReviewer: boolean) => {
        if (isReviewer) {
            const newArray = [...reviewers];
            newArray.splice(index, 1);
            setReviewers(newArray);
        } else {
            const newArray = [...keywords];
            newArray.splice(index, 1);
            setKeywords(newArray);
        }
    };

    const handleSubmit = async () => {
        return doSubmit(submission, file)
            .then((response) => {
                if (response.error) {
                    return;
                }

                console.log('Submission > POST > doSubmit: ', response);

                handleModalClose();
            })
            .catch((error) => {
                console.error('Submission > LogIn failed with error: ', error.response ? error.response.data : error.message);

                return;
            })
            .then(() => {
                doGetArticles()
                    .then((data) => {
                        console.log("Submission > GET > doGetArticles: ", data);

                        handleModalClose();
                        setData(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            })
    }

    console.log('Submission > submission: ', submission);

    return (<div className={'submission-container'}>
        <div className={'submission-header'}>
            <div>
                <button id={'closeSubmissionModal'} onClick={handleModalClose}>Close</button>
            </div>
            <div className={'submission-header-title'}>
                {'Submit new article'}
            </div>
        </div>
        <br/>

        <div className={'submission-content'}>
            <label>{'Title'}</label>
            <input type={'text'} id={'submission.title'} name={'title'} value={submission.title} onChange={(event) => {handleChangeInput(event, true)}}/>
            <br/>

            <label>{'Abstract'}</label>
            <input type={'text'} id={'submission.abstractText'} name={'abstractText'} value={submission.abstractText} onChange={(event) => {handleChangeInput(event, true)}}/>
            <br/>

            <label>{'Country'}</label>
            <select id={'submission.author.countryId'} name={'countryId'} value={author.countryId} onChange={(event) => {handleChangeInput(event, false)}}>
                {countryOptions.map((option, index) => (
                    <option key={index} id={'countryOptions[' + index.toString() + ']'} value={option.id}>{option.name}</option>
                ))}
            </select>
            <br/>

            <label>{'Has received funding'}</label>
            <input type={'checkbox'} id={'submission.hasReceivedFunding'} name={'hasReceivedFunding'} checked={submission.hasReceivedFunding} onChange={(event) => {handleChangeCheckboxInput(event)}}/>
            <br/>

            {keywords.map((keyword, index) => (
                <div key={index}>
                    <label>{'Keyword'}</label>
                    <input
                        type={'text'}
                        id={'submission.keywords[' + index.toString() + ']'}
                        name={'keyword'}
                        value={keyword}
                        onChange={(event) => {handleChangeArrayInput(event, index, false)}}
                    />
                    <br/>
                    {keywords.length > 1 && (
                        <button onClick={() => handleDeleteInput(index, false)}>Delete</button>
                    )}
                    <br/>
                    {index === keywords.length - 1 && (
                        <button onClick={() => handleAddInput(false)}>Add</button>
                    )}
                </div>
            ))}
            <br/>

            {reviewers.map((reviewer, index) => (
                <div key={index}>
                    <label>{'Email'}</label>
                    <input type={'text'} id={'submission.reviewers[' + index.toString() + '].email'} name={'email'} value={reviewer.email} onChange={(event) => {handleChangeArrayInput(event, index, true)}}/>
                    <br/>
                    <label>{'First name'}</label>
                    <input type={'text'} id={'submission.reviewers[' + index.toString() + '].firstName'} name={'firstName'} value={reviewer.firstName} onChange={(event) => {handleChangeArrayInput(event, index, true)}}/>
                    <br/>
                    <label>{'Last name'}</label>
                    <input type={'text'} id={'submission.reviewers[' + index.toString() + '].lastName'} name={'lastName'} value={reviewer.lastName} onChange={(event) => {handleChangeArrayInput(event, index, true)}}/>
                    <br/>
                    <label>{'Affiliation'}</label>
                    <input type={'text'} id={'submission.reviewers[' + index.toString() + '].affiliation'} name={'affiliation'} value={reviewer.affiliation} onChange={(event) => {handleChangeArrayInput(event, index, true)}}/>
                    <br/>
                    {reviewers.length > 1 && (
                        <button onClick={() => handleDeleteInput(index, true)}>Delete</button>
                    )}
                    <br/>
                    {index === reviewers.length - 1 && (
                        <button onClick={() => handleAddInput(true)}>Add</button>
                    )}
                </div>
            ))}
        </div>

        <label htmlFor="file">{'File'}</label>
        <input id='file' name={'file'} type='file' onChange={handleFileChange} />
        <br/>
        <br/>

        <button onClick={handleSubmit} disabled={isSubmitButtonDisabled}>Submit</button>
    </div>)
}

export default Submission;